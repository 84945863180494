import { AppsOutlined, AutoStoriesOutlined, CalendarMonthOutlined, ChatOutlined, NaturePeopleOutlined, SettingsOutlined } from "@mui/icons-material";

const pages = {
  catalog: {
    name: "Catalog",
    url: "catalog",
    icon: AutoStoriesOutlined,
  },
  resources: {
    name: "Resources",
    url: "resources",
    icon: NaturePeopleOutlined,
  },
  chat: {
    name: "Chat",
    url: "chat",
    icon: ChatOutlined,
  },
  calendar: {
    name: "Calendar",
    url: "calendar",
    icon: CalendarMonthOutlined,
  },
  integrations: {
    name: "Integrations",
    url: "integrations",
    icon: AppsOutlined,
  },
  configuration: {
    name: "Configuration",
    url: "configuration",
    icon: SettingsOutlined,
  },
};

export const PageMenuContents = ({
  page,
  collapse,
}: {
  page: string,
  collapse?: boolean,
}) => {
  return (
    <div className="flex flex-col">
      {Object.entries(pages).map(([key, { name, icon: Icon }]) => {
        return (
          <div className={`${key === page ? "bg-indigo-200/50 rounded-md" : ""} px-3 h-14 flex items-center ${collapse ? "" : "gap-2"}`}>
            <Icon style={{
              color: "rgb(51 65 85)",
              width: collapse ? "30px" :"25px",
              height: collapse ? "30px" :"25px",
              transition: "width .15s linear, height .15s linear",
            }} />

            <p className={`
              text-slate-700 ${key === page ? "font-semibold" : ""} text-lg
              ${collapse ? "w-0 opacity-0" : "w-max opacity-100"} transition-all
            `}>
              {name}
            </p>
          </div>
        );
      })}
    </div>
  );
}
