import { createSelector } from "@reduxjs/toolkit";
import { Repository } from "../../core/repository/repository";
import { selectState } from "../store";

export const selectCapabilitiesState = createSelector(
  [selectState],
  (state) => state.capabilities,
);

export const selectCapabilitiesStatus = createSelector(
  [selectCapabilitiesState],
  (state) => state.status,
);

export const selectCapabilities = createSelector(
  [selectCapabilitiesState],
  (state) => state.value,
);

export const selectCapabilityRepository = createSelector(
  [selectCapabilitiesState],
  (state) => new Repository(state.value),
);
