export const Input = ({
  value,
  label,
  placeholder,
  textarea,
  type,
  onChange,
  error,
}: {
  value?: string | number,
  label?: string,
  placeholder?: string,
  textarea?: boolean,
  type?: string,
  onChange?: (value: string) => void,
  error?: string,
}) => {
  return (
    <div className="w-full">
      {label &&
        <p className="text-slate-700 font-medium mb-2 ml-1">
          {label}
        </p>
      }

      {textarea ? 
        <textarea
          className={`
            rounded-md border border-1 w-full h-[48px] focus:outline-0
            p-3 text-slate-700 resize-none overflow-y-hidden
          `}
          spellCheck="false"
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            e.currentTarget.style.height = "1px";
            e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
            onChange?.(e.target.value)
          }}
        />
        :
        <input
          type={type}
          className={`
            rounded-md border border-1 w-full h-[48px] focus:outline-0
            p-3 text-slate-700 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none] [&::-webkit-inner-spin-button]:appearance-none
          `}
          min="0"
          spellCheck="false"
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            onChange?.(e.target.value)
          }}
        />
      }

      {error &&
        <p className="text-red-500 text-sm mt-2 ml-1">
          {error}
        </p>
      }
    </div>
  );
}
