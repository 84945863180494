import { createAsyncThunk } from "@reduxjs/toolkit";
import { SupabaseApi } from "../../api/supabase";
import { AppointmentInterface } from "./interfaces";

const timeFromUTC = (time: string) => {
  const [hour, minutes, seconds] = time.split(":");

  const validatedTime = `${(hour || "00").padStart(2, "0")}:${minutes || "00"}:${seconds || "00"}`;

  const wrappedTime = new Date(`1970-01-01T${validatedTime}Z`).getTime();
  const offset = new Date().getTimezoneOffset();
  const newTime = new Date(wrappedTime - offset * 60 * 1000);

  return `${newTime.getUTCHours().toString().padStart(2, "0")}:${newTime.getUTCMinutes().toString().padStart(2, "0")}:${newTime.getUTCSeconds().toString().padStart(2, "0")}`;
}

const dateFromUTC = (time: string) => {
  const wrappedTime = new Date(time).getTime();
  const offset = new Date().getTimezoneOffset();
  const newTime = new Date(wrappedTime - offset * 60 * 1000);

  return `${newTime.getUTCHours().toString().padStart(2, "0")}:${newTime.getUTCMinutes().toString().padStart(2, "0")}:${newTime.getUTCSeconds().toString().padStart(2, "0")}`;
}

export const getAppointmentsThunk = createAsyncThunk(
  "appointments/getAppoinments",
  async (commerceId: string) => {
    const response = await SupabaseApi.supabase
      .from("appointment")
      .select()
      .eq("commerce_id", commerceId);

    const utc = response.data as any as AppointmentInterface[];

    utc.map(({ scheduled_start_at, scheduled_end_at, time, ...appointment }) => {
      return {
        ...appointment,
        scheduled_start_at: scheduled_start_at && dateFromUTC(scheduled_start_at),
        scheduled_end_at: scheduled_end_at && dateFromUTC(scheduled_end_at),
        time: time && timeFromUTC(time),
      };
    })

    return response.data as any as AppointmentInterface[];
  },
);

export const removeAppointmentThunk = createAsyncThunk(
  "appointments/removeAppoinment",
  async (id: string) => {
    await SupabaseApi.supabase.from("appointment").delete().eq("id", id);

    return id;
  },
);
