import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { Modal } from "../modal/modal";

export const ConfirmDeleteModal = ({
  title = "Confirm delete",
  onClose,
  onDelete,
}: {
  title: string,
  onClose: () => void,
  onDelete: () => Promise<void>,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal title={title} onClose={onClose}>
      <div className="flex w-full items-center justify-center py-3 gap-3">
        <p
          className={`
            w-20 border-2 rounded-full font-medium
            cursor-pointer flex items-center justify-center
            ${loading ? "bg-gray-200 py-2" : "text-indigo-500 border-indigo-500 hover:border-indigo-400 hover:text-indigo-400 py-1"}
          `}
          onClick={async () => {
            if (loading) return;

            setLoading(true);
            await onDelete();
            setLoading(false);
            onClose();
          }}
        >
          {loading ? 
            <CircularProgress
              size={18}
              sx={{
                color: "rgb(192 132 252)",
              }}
            />
          : "Delete"}
        </p>

        <p
          onClick={onClose}
          className={`
            px-4 py-1 border-2 border-indigo-500 rounded-full bg-indigo-500
            text-white font-medium cursor-pointer
            hover:bg-indigo-400 hover:border-indigo-400
          `}
        >
          Cancel
        </p>
      </div>
    </Modal>
  );
}
