import { createSlice } from "@reduxjs/toolkit";
import { defaultCases, initListState } from "../utils";
import { AppointmentInterface } from "./interfaces";
import { getAppointmentsThunk, removeAppointmentThunk } from "./thunks";

const appointmentSlice = createSlice({
  name: "appointment",
  initialState: initListState<AppointmentInterface>(),
  reducers: {},
  extraReducers: (builder) => {
    defaultCases(builder, getAppointmentsThunk).addCase(
      getAppointmentsThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = action.payload;
      },
    );

    defaultCases(builder, removeAppointmentThunk).addCase(
      removeAppointmentThunk.fulfilled,
      (state, action) => {
        state.status = "fulfilled";
        state.value = state.value.filter(({ id }) => action.payload !== id);
      },
    );
  },
});

export const appointmentReducer = appointmentSlice.reducer;
