import { ChevronLeft } from "@mui/icons-material";
import { useState } from "react";
import Logo from "../../assets/mb-logo.svg";

export const PageLogo = ({
  collapse,
  onCollapse,
}: {
  collapse: boolean,
  onCollapse?: () => void,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="flex items-center -mt-2 p-3">
      <div
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={`w-11 h-11 ${collapse ? "" : "mr-3"}`}
      >
        {!hover || !collapse ? 
          <PageLogoIcon />
          : <CollapseButton
              onClick={() => onCollapse?.()}
              collapse={collapse}
            />
        }
      </div>
      
      {!collapse &&
        <div
          className={`font-logo-semibold text-3xl font-semibold text-slate-700`}
        >
          Magnetic
          <p className="font-logo text-indigo-600 -mt-3">Bunny</p>
        </div>
      }

      {!collapse && <CollapseButton collapse={collapse} onClick={() => onCollapse?.()} />}
    </div>
  );
}

const CollapseButton = ({
  onClick,
  collapse,
}: {
  onClick: () => void,
  collapse: boolean,
}) => {
  return (
    <div
      className={`
        flex items-center justify-center cursor-pointer w-10 h-10
        rounded-full ml-auto transition-all
        ${collapse ? "bg-gray-200 opacity-100" : "bg-gray-0 hover:bg-gray-200 opacity-0 hover:opacity-100"}
      `}
      onClick={onClick}
    >
      <ChevronLeft
        style={{
          width: "30px",
          height: "30px",
          transform: collapse ? "rotate(180deg)" : "",
        }}
      />
    </div>
  );
}

export const PageLogoIcon = () => {
  return (
    <div className={`bg-slate-700 p-1 rounded-md`}>
      <img id="logo" src={Logo} alt="Logo" className={`"w-9 h-9`} />
    </div>
  );
}
