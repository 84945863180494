import { CheckOutlined } from "@mui/icons-material";

export const Checkbox = ({
  value,
  onClick,
}: {
  value: boolean,
  onClick: () => void,
}) => {
  return (
    <div
      className={`
          flex items-center justify-center
          ${value ? "" : "border border-gray-300"}
          rounded-md
          ${value ? "bg-indigo-400" : ""}
          w-5 h-5 mb-1
          ${value ? "hover:bg-indigo-200" : "hover:bg-gray-100"}
          cursor-pointer
        `}
      onClick={onClick}
    >
      {value ? (
        <CheckOutlined
          sx={{
            color: "white",
            height: "1.25rem",
            width: "1.25rem",
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
