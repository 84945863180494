import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { translations } from "../../../core/constants/translations";
import { useLangParam } from "../../../core/hooks";
import { AppDispatch } from "../../../store/store";
import { selectUserErrors } from "../../../store/user/selectors";
import { setUserError } from "../../../store/user/user";

export const LoginStage = ({
  email,
  setEmail,
  password,
  setPassword,
  onSubmit,
  onPasswordReset,
}: {
  email: string;
  setEmail: (e: string) => void;
  password: string;
  setPassword: (e: string) => void;
  onSubmit: () => void;
  onPasswordReset: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();
  const translation = translations["Get Started"]["Login"];

  const navigate = useNavigate();

  const error = useSelector(selectUserErrors);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`bg-white w-80 h-96 p-8 lg:w-96 lg:h-96 lg:p-16 rounded-lg flex flex-col items-center gap-3 justify-center`}>
      <p className="text-2xl font-bold mb-8">{translation[lang]}</p>

      <TextField
        label={translation["Email"][lang]}
        variant="outlined"
        type="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        size="small"
        sx={{
          width: "250px",
        }}
      />

      <TextField
        label={translation["Password"][lang]}
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        size="small"
        sx={{
          width: "250px",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setShowPassword((v) => !v);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          style: {
            paddingRight: "0px",
          },
        }}
      />

      <p
        className="text-cyan-600 hover:text-cyan-500 cursor-pointer self-start ml-1 text-xs"
        onClick={() => {
          onPasswordReset();
        }}
      >
        {translation["Forgot password?"][lang]}
      </p>

      <Button
        variant="contained"
        size="large"
        sx={{
          width: "250px",
          background: "oklch(30% 0 0)",
          "&:hover": {
            background: "oklch(40% 0 0)",
          },
        }}
        onClick={onSubmit}
        disabled={!email}
      >
        {translation["Button"][lang]}
      </Button>

      {error && <p className="text-red-600">{error}</p>}

      <p
        className="text-cyan-600 hover:text-cyan-500 cursor-pointer self-start ml-1 text-xs"
        onClick={() => {
          navigate("/register");
          dispatch(setUserError(undefined));
        }}
      >
        Don't have an account? Register here
      </p>
    </div>
  );
};
