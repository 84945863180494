import { LiveStreamMessages } from "../../../../components/live-stream-component/live-stream-messages";
import { LiveViewCalendar } from "./calendar";

export const LiveView = () => {
  return (
    <div className={`h-full max-lg:overflow-y-scroll flex flex-col items-center`}>
      <div className="w-full">
        <h1 className="lg:hidden text-4xl font-logo-semibold text-center m-4 select-none">
          Live<span className="font-logo text-slate-400">Action</span>
        </h1>

        <h1 className="max-lg:hidden text-4xl font-logo-semibold text-center m-4 select-none">
          Magnetic<span className="font-logo text-slate-400">Demo</span>
        </h1>
      </div>

      <div className={"w-[300px] bg-white/60 rounded-xl"}>
        <LiveViewCalendar />
      </div>

      <div className="pb-4">
        <LiveStreamMessages />
      </div>
    </div>
  );
};
