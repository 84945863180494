import { CircularProgress } from "@mui/material";
import { useState } from "react";

export const PlatformButton = ({
  title,
  onClick,
  className,
  textClassName = "text-white",
}: {
  title: string,
  onClick: () => Promise<void>,
  className?: string,
  textClassName?: string,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div
      className={`flex items-center justify-center px-4 py-1 rounded-xl ml-auto cursor-pointer select-none ${className}`}
      onClick={async () => {
        setLoading(true);
        await onClick()
        setLoading(false);
      }}
    >
      <p className={textClassName}>
        {loading ? <CircularProgress
          style={{
            color: "white",
            height: "10px",
            width: "10px",
          }}
        /> : title}
      </p>
    </div>
  );
}
