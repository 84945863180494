import { Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translations } from "../../../../core/constants/translations";
import { useLangParam } from "../../../../core/hooks";
import { selectCapabilities } from "../../../../store/capabilities/selectors";
import {
  addCapabilitiesThunk,
  removeCapabilityThunk,
} from "../../../../store/capabilities/thunks";
import { selectCommerceId } from "../../../../store/commerce/selectors";
import { ResourceInterface } from "../../../../store/resource/interfaces";
import {
  addResourceThunk,
  updateResourceThunk,
} from "../../../../store/resource/thunks";
import { addResourceTimeframesThunk } from "../../../../store/resource_timeframe/thunks";
import { selectServices } from "../../../../store/service/selectors";
import { AppDispatch } from "../../../../store/store";
import { selectTimeframes } from "../../../../store/timeframe/selectors";
import { ServiceAssociationItem } from "./service-association-item";

export const ChangeResourceScreen = ({
  resource,
  onBack,
}: {
  resource?: ResourceInterface;
  onBack: (resource: string) => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Onboarding"]["New resource"];

  const [loading, setLoading] = useState(false);

  const commerceId = useSelector(selectCommerceId);

  const capabilities = useSelector(selectCapabilities);
  const resourceCapabilities = capabilities.filter((c) => {
    return c.resource_id === resource?.id;
  });

  const services = useSelector(selectServices);
  const timeframes = useSelector(selectTimeframes);

  const [name, setName] = useState(resource?.name);
  const [description, setDescription] = useState(resource?.description);

  const [choosenServices, setChoosenServices] = useState<string[]>(
    resourceCapabilities.map(({ service_id }) => service_id),
  );

  return (
    <div className={`bg-white w-80 lg:w-96 h-max rounded-lg flex flex-col items-center`}>
      <div className="w-full p-8 flex flex-col gap-2">
        <TextField
          label={translation["Name"][lang]}
          size="small"
          sx={{
            width: "100%",
          }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <TextField
          multiline
          label={translation["Description Title"][lang]}
          size="small"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <p className="text-xs text-gray-600 pt-3">
          {translation["Service association"][lang]}
        </p>

        <div className={`max-lg:max-h-60 max-lg:overflow-y-scroll flex flex-col`}>
          {services.map((service, i, list) => {
            const isChoosen = choosenServices.includes(service.id);
            return (
              <ServiceAssociationItem
                service={service}
                index={i}
                last={list.length - 1 === i}
                isChecked={isChoosen}
                onClick={() => {
                  if (!isChoosen) {
                    setChoosenServices([...choosenServices, service.id]);
                  } else {
                    setChoosenServices(
                      choosenServices.filter((s) => s !== service.id),
                    );
                  }
                }}
              />
            );
          })}
        </div>

        <p className="text-xs text-gray-600 pb-3">
          * These fields are mandatory
        </p>

        <Button
          variant="contained"
          sx={{
            width: "100%",
            background: "rgb(192 132 252)",
            "&:hover": {
              background: "rgb(216 180 254)",
            },
          }}
          disabled={!name || !description || loading}
          onClick={() => {
            setLoading(true);

            if (resource)
              commerceId &&
                dispatch(
                  updateResourceThunk({
                    id: resource.id,
                    name,
                    description,
                    commerce_id: commerceId,
                  }),
                )
                  .unwrap()
                  .then(async ({ id }) => {
                    for (const { id } of resourceCapabilities) {
                      await dispatch(removeCapabilityThunk(id));
                    }

                    choosenServices.forEach((s) => {
                      dispatch(
                        addCapabilitiesThunk({
                          resource_id: id,
                          service_id: s,
                          commerce_id: commerceId,
                        }),
                      );
                    });

                    setLoading(false);
                    onBack(id);
                  });
            else
              commerceId &&
                dispatch(
                  addResourceThunk({
                    name,
                    description,
                    commerce_id: commerceId,
                  }),
                )
                  .unwrap()
                  .then(async ({ id }) => {
                    for (const { id } of resourceCapabilities) {
                      await dispatch(removeCapabilityThunk(id));
                    }

                    timeframes.forEach(({ day, start, duration }) => {
                      dispatch(
                        addResourceTimeframesThunk({
                          day,
                          start,
                          duration,
                          resource_id: id,
                        }),
                      );
                    });

                    await Promise.all(choosenServices.map(async (s) => {
                      return await dispatch(
                        addCapabilitiesThunk({
                          resource_id: id,
                          service_id: s,
                          commerce_id: commerceId,
                        }),
                      );
                    }));

                    setLoading(false);
                    onBack(id);
                  });
          }}
        >
          {loading ? 
            <CircularProgress
              size={25}
              sx={{
                color: "rgb(192 132 252)",
              }}
            />
            : translation["Save"][lang]}
        </Button>
      </div>
    </div>
  );
};
