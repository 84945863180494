import { MessagesGroupBox } from "./messages";
import { groupBy } from "./utils";
import { ChatInput } from "./input";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { AppDispatch } from "../../store/store";
import { addMessage, MessageInterface } from "../../store/agents";
import { getResponseThunk, startNewConversationThunk } from "../../store/agents/thunks";
import { addCustomerThunk, getCustomerThunk } from "../../store/customer/thunks";
import { selectCommerceId } from "../../store/commerce/selectors";
import { selectCurrentCustomer, selectCurrentCustomerId } from "../../store/customer/selectors";
import { setCurrentCustomer } from "../../store/customer/customer";
import AnonUser from "../../assets/anon-user.png";
import { selectMessages, selectMessagesCurrentlyLoading } from "../../store/agents/selectors";
import { getAppointmentsThunk } from "../../store/appointment/thunks";
import { TypingComponent } from "./typing.component";
import { addCustomerLink } from "../../store/customer-link/thunks";
import { translations } from "../../core/constants/translations";
import { useLangParam } from "../../core/hooks";
import { AddCommentOutlined } from "@mui/icons-material";

export interface ChatMessageInterface {
  body: string[];
  sender: "user" | "bot";
}

export function ChatComponent() {
  const dispatch = useDispatch<AppDispatch>();
  const translation = translations["Demo"]["Chat"];
  const lang = useLangParam();

  const customerId = useSelector(selectCurrentCustomerId);
  const customer = useSelector(selectCurrentCustomer);

  const messages: MessageInterface[] = useSelector(selectMessages).filter(({ customerId }) => {
    return customerId === customer?.id;
  });
  const currentlyLoadingCustomer = useSelector(selectMessagesCurrentlyLoading);
  const commerceId = useSelector(selectCommerceId);

  return (
    <div className="h-full grid grid-rows-[60px_1fr_max-content]">
      {(customerId || customer) ?
        <div className="bg-white w-full flex gap-3 items-center px-3">
          <img
            alt="anon-user"
            src={AnonUser}
            className="h-10 w-10"
          />

          <p className="max-lg:text-sm">
            {customer?.first_name && customer?.last_name ?
              `${customer.first_name} ${customer.last_name}`
              : customer?.id}
          </p>

          <p
            className={`ml-auto mr-3 text-sm p-2 rounded-full lg:px-2 lg:py-1 lg:rounded-md text-white bg-indigo-300 font-medium hover:bg-indigo-400 cursor-pointer`}
            onClick={() => {
              commerceId && dispatch(startNewConversationThunk({
                commerceId,
                customerId,
              }));
            }}
          >
            <div className="lg:hidden">
              <AddCommentOutlined />
            </div>

            <p className="max-lg:hidden">
              {translation["New conversation"][lang]}
            </p>
          </p>
        </div>
        : <div />
      }

     <div
        className={`
          overflow-y-scroll flex flex-col-reverse grow gap-3
          scrollbar scrollbar-thumb-rounded-full scrollbar-thumb-indigo-400/25
          scrollbar-w-1.5
          pr-3 p-4 mb-6
        `}
      >
        {currentlyLoadingCustomer.includes(customer?.id || "") &&
          <TypingComponent />
        }

        {groupBy(messages, "sender").map((messages, i) => {
          return <MessagesGroupBox key={i} messages={messages} />
        }).reverse()}
      </div>

      <div className={`px-2 lg:p-4`}>
        <ChatInput
          onSubmit={async (text) => {
            let newCustomerId: string | undefined = undefined;

            if (!customerId) {
              const { id } = await dispatch(addCustomerThunk({})).unwrap();

              dispatch(setCurrentCustomer(id))
              commerceId && dispatch(addCustomerLink({ commerceId, customerId: id }))

              newCustomerId = id;
            }

            if (!(commerceId)) return;

            dispatch(addMessage({
              body: [text],
              customerId: customerId || newCustomerId || "",
              commerceId,
            }));
            dispatch(
              getResponseThunk({
                text,
                customerId: customerId || newCustomerId || "",
                commerceId,
              }),
            )
            .then(unwrapResult)
            .then(() => {
              dispatch(getCustomerThunk(customerId ?? newCustomerId ?? ""));
              dispatch(getAppointmentsThunk(commerceId));
            });
          }}
        />
      </div>
    </div>
  );
}
