import { CloseOutlined } from "@mui/icons-material";

export const Modal = ({
  title,
  onClose,
  hideClose,
  children,
}: {
  title: string;
  hideClose?: boolean;
  onClose?: () => void;
  children: JSX.Element;
}) => {
  return (
    <div
      className="absolute bg-gray-800/90 backdrop-blur-sm h-screen w-screen z-30 top-0 left-0 flex flex-col items-center justify-center cursor-default"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={`w-80 lg:w-[500px] h-max bg-white rounded-xl`}>
        <div className="flex flex-col w-full items-center py-5 border-b border-gray-300">
          {!hideClose && (
            <div className={`relative h-0 w-0 -left-[8.875rem] lg:-left-[230px]`}>
              <div className="absolute cursor-pointer">
                <CloseOutlined
                  onClick={onClose}
                  style={{
                    color: "rgb(51 65 85)",
                  }}
                />
              </div>
            </div>
          )}

          <p className="text-xl font-medium text-slate-700">{title}</p>
        </div>

        {children}
      </div>
    </div>
  );
};
