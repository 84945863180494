import { Send } from "@mui/icons-material";
import { createRef, useState } from "react";
import { useLangParam } from "../../core/hooks";
import { ChooseCustomer } from "./choose-customer";
import { translations } from "../../core/constants/translations";

export const ChatInput = ({
  onSubmit,
}: {
  onSubmit: (message: string) => void;
}) => {
  const [input, setInput] = useState("");
  const lang = useLangParam();
  const translation = translations["Demo"]["Chat"];

  const inputRef = createRef<HTMLTextAreaElement>();

  return (
    <div
      className="flex gap-2 items-end justify-between"
    >
      <ChooseCustomer />

      <textarea
        ref={inputRef}
        placeholder={translation["Placeholder"][lang]}
        value={input}
        onChange={(e) => {
          e.currentTarget.style.height = "1px";
          e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
          setInput(e.target.value);
        }}
        className="grow p-3 px-5 rounded-[25px] h-10 border-none focus:outline-0 h-[48px] resize-none"
        onKeyDown={(event) => {
          if (event.key === "Enter" && input) {
            event.preventDefault();
            event.currentTarget.style.height = "48px";

            onSubmit(input);
            setInput("");
          }
        }}
      />

      <InputButton
        onClick={() => {
          if (input) {
            onSubmit(input);
            setInput("");
            if (inputRef.current) {
              inputRef.current.style.height = "48px";
            }
          }
        }}
      />
    </div>
  );
};

const InputButton = ({ onClick }: { onClick: () => void }) => {
  const [hover, setHover] = useState(false);

  return (
    <button
      onClick={onClick}
      className="bg-indigo-600 rounded-full hover:indigo-500"
      style={{
        width: "40px",
        height: "40px",
        userSelect: "none",
        cursor: "pointer",
        transform: hover ? "scale(1.2)" : "scale(1.0)",
        transition: "transform 0.1s linear",
        marginBottom: "5px",
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Send
        style={{
          color: "white",
          width: "25px",
          height: "25px",
          marginLeft: "4px",
          marginBottom: "1px",
        }}
      />
    </button>
  );
};
