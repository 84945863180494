import { ExpandMore } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { PlatformButton } from "../../components/buttons/button";
import { ServiceInterface } from "../../store/service/interfaces";
import { selectServices, selectServiceStatus } from "../../store/service/selectors";

export const ServicesList = ({
  createService,
  setService,
}: {
  createService: () => void,
  setService: (service: string) => void,
}) => {
  const [filter, setFilter] = useState<string>("none");

  const services = useSelector(selectServices);
  const filteredServices = [...services].sort((serviceA, serviceB) => {
    switch (filter) {
    default:
    case "name-descending":
      return serviceA.name?.localeCompare(serviceB.name || "") || 0
    case "name-ascending":
      return serviceB.name?.localeCompare(serviceA.name || "") || 0
    case "duration-descending":
      return (serviceA.duration || 0) - (serviceB.duration || 0)
    case "duration-ascending":
      return (serviceB.duration || 0) - (serviceA.duration || 0)
    case "price-descending":
      return (serviceA.price || 0) - (serviceB.price || 0)
    case "price-ascending":
      return (serviceB.price || 0) - (serviceA.price || 0)
    }
  });

  const servicesLoading = useSelector(selectServiceStatus) !== "fulfilled";

  return (
    <div className="flex flex-col h-[calc(100%-150px)] gap-5 pt-5 lg:gap-10 lg:pt-10">
      <div className="flex justify-center items-center h-16 w-full bg-white/50 rounded-2xl p-5">
        <p className={`text-slate-700 text-xl lg:text-2xl font-medium`}>
          Catalog Menu
        </p>

        <PlatformButton
          title="Add Service"
          className="w-30 h-10 bg-indigo-500 hover:bg-indigo-400"
          onClick={async () => {
            createService();
          }}
        />
      </div>

      <div className="h-full">
        <div className={`
          flex flex-col justify-center w-full bg-white/50 rounded-2xl pb-5 pt-2
          h-auto max-h-full
        `}>
          <ServicesBar
            setFilter={setFilter}
            filter={filter}
          />

          <div className={`flex flex-col justify-center w-full overflow-y-auto`}>
            {servicesLoading &&
              [1, 2, 3].map((i) => {
                const isLast = i === 3;

                return (
                  <div
                    className={`
                      grid grid-cols-3 items-center py-2 hover:bg-gray-200/50 border-t border-t-1
                      px-5 ${isLast ? "border-b border-b-1" : ""} cursor-pointer select-none
                    `}
                  >
                    <div className="flex gap-8 items-center">
                      <div className={`h-9 w-9 rounded-full flex items-center justify-center`}>
                        <Skeleton
                          variant="circular"
                          style={{
                            width: "2.25rem",
                            height: "2.25rem",
                          }}
                        />
                      </div>

                      <Skeleton
                        style={{
                          width: "100px",
                        }}
                      />
                    </div>

                    <Skeleton
                      style={{
                        width: "100px",
                        marginLeft: "auto",
                        marginRight: "0.75rem",
                      }}
                    />

                    <Skeleton
                      style={{
                        width: "100px",
                        marginLeft: "auto",
                        marginRight: "0.75rem",
                      }}
                    />
                  </div>
                );
              })
            }

            {servicesLoading || filteredServices.map((service, i, list) => {
              const isLast = list.length - 1 === i;

              return (
                <ServiceItem
                  service={service}
                  isLast={isLast}
                  onClick={() => setService(service.id)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const ServiceItem = ({
  onClick,
  isLast,
  service,
}: {
  isLast: boolean,
  service: ServiceInterface,
  onClick: () => void,
}) => {
  return (
    <div
      className={`
        grid grid-cols-[3fr_1fr] lg:grid-cols-3 items-center py-2 hover:bg-gray-200/50 border-t border-t-1
        px-5 ${isLast ? "border-b border-b-1" : ""} cursor-pointer select-none
      `}
      onClick={onClick}
    >
      <div className="flex gap-8 items-center">
        <div className={`h-9 w-9 rounded-full flex items-center justify-center ${service.color}`}>
          <p className="font-medium text-sm">
            {service.name?.slice(0, 2).toUpperCase()}
          </p>
        </div>

        <p className="font-medium text-slate-700">
          {service.name}
        </p>
      </div>

      <div className="lg:hidden flex flex-col gap-1">
        <p className="text-right text-sm text-slate-500 pr-3">
          {service.duration} min
        </p>

        <p className="text-right text-sm font-medium text-slate-700 pr-3">
          {service.price} {service.currency}
        </p>
      </div>

      <p className="max-lg:hidden text-right text-slate-500 pr-3">
        {service.duration} min
      </p>

      <p className="max-lg:hidden text-right font-medium text-slate-700 pr-3">
        {service.price} {service.currency}
      </p>
    </div>
  );
}

const ServicesBar = ({
  filter,
  setFilter,
}: {
  filter: string,
  setFilter: (filter: string) => void,
}) => {
  return (
    <div className={`grid grid-cols-[3fr_1fr] lg:grid-cols-3 px-5 py-3`}>
      <ServicesBarFilter
        left
        name={"Name"}
        filterType="name"
        setFilter={setFilter}
        filter={filter}
      />

      <ServicesBarFilter
        hideMobile
        name={"Duration"}
        filterType="duration"
        setFilter={setFilter}
        filter={filter}
      />

      <ServicesBarFilter
        name={"Price"}
        filterType="price"
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  )
}

const ServicesBarFilter = ({
  name,
  filter,
  setFilter,
  filterType,
  left,
  hideMobile,
}: {
  name: string,
  filterType: string,
  left?: boolean,
  filter: string,
  setFilter: (filter: string) => void,
  hideMobile?: boolean,
}) => {
  const [isHover, setHover] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`flex pr-3 cursor-pointer ${hideMobile ? "max-lg:hidden" : ""} ${left ? "pl-[70px]" : "ml-auto"}`}
      onClick={() => {
        switch (filter) {
        default:
          setFilter(`${filterType}-descending`)
          break;
        case `${filterType}-descending`:
          setFilter(`${filterType}-ascending`)
          break;
        case `${filterType}-ascending`:
          setFilter("none")
          break;
        }
      }}
    >
      <p className="text-slate-700 font-medium select-none">
        {name}
      </p>

      {(isHover || filter.includes(filterType)) &&
        <div className="relative">
          <div className="absolute -right-6">
            <ExpandMore
              style={{
                color: filter.includes(filterType) ? "rgb(51 65 85)" : "rgb(100 116 139)",
                transform: `${filter.includes("ascending") ? "rotate(180deg)" : ""}`,
              }}
            />
          </div>
        </div>
      }
    </div>
  );
}
