import { MenuOutlined } from "@mui/icons-material";
import { useState } from "react";
import { PageMenuContents } from "./menu/menu";
import { PageLogoIcon } from "./page-logo";
import { PageUser } from "./page-user";

export const PageTopbar = ({
  page,
}: {
  page: string,
}) => {
  return (
    <>
      <Mobile page={page} />

      <Desktop />
    </>
  );
}

const Mobile = ({
  page,
}: {
  page: string,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="lg:hidden flex items-center h-16 w-full bg-white/50 rounded-2xl p-4">
      <PageLogoIcon />

      <div className="flex items-center gap-2 ml-auto">
        <PageUser />

        <MenuOutlined
          style={{
            color: "rgb(51 65 85)",
            width: "32px",
            height: "32px",
          }}
          onClick={() => {
            setOpenMenu(true);
          }}
        />
      </div>

      {openMenu &&
        <MobilePageMenu
          page={page}
          onClose={() => {
            setOpenMenu(false);
          }}
        />
      }
    </div>
  );
}

const Desktop = () => {
  return (
    <div className="max-lg:hidden flex items-center h-16 w-full bg-white/50 rounded-2xl p-4">
      <PageUser />
    </div>
  );
}

const MobilePageMenu = ({
  page,
  onClose,
}: {
  page: string,
  onClose: () => void,
}) => {
  return (
    <>
      <div 
        className="fixed right-0 top-0 w-screen h-dvh bg-gray-400/30 backdrop-blur-sm"
        onClick={onClose}
      />

      <div className="fixed w-60 h-dvh shadow-md p-5 top-0 right-0 bg-white">
        <PageMenuContents
          page={page}
        />
      </div>
    </>
  );
}
