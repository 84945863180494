import { createSelector } from "@reduxjs/toolkit";
import { Repository } from "../../core/repository/repository";
import { selectState } from "../store";

export const selectAppointmentsState = createSelector(
  [selectState],
  (state) => state.appointments,
);

export const selectAppointmentsStatus = createSelector(
  [selectAppointmentsState],
  (state) => state.status,
);

export const selectAppointments = createSelector(
  [selectAppointmentsState],
  (state) => state.value,
);

export const selectAppointmentRepository = createSelector(
  [selectAppointmentsState],
  (state) => new Repository(state.value),
);
