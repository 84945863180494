import { useState } from "react";
import { PageWrapper } from "../../components/page-wrapper/page-wrapper";
import { useLoader } from "../../core/loader/loader";
import { EditService } from "./edit-service/edit-service";
import { ServicesList } from "./services-list";

export const CatalogPage = () => {
  useLoader();

  const [currentService, setCurrentService] = useState<string>();
  const [createService, setCreateService] = useState(false);

  return (
    <PageWrapper page="catalog">
      {currentService || createService ?
        <EditService
          createService={createService}
          serviceId={currentService}
          onBack={() => {
            setCurrentService(undefined);
            setCreateService(false);
          }}
        />
        : <ServicesList
            createService={() => {
              setCreateService(true);
            }}
            setService={setCurrentService}
          />
      }
    </PageWrapper>
  );
}
