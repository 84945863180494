import { Input } from "../../../components/inputs/input";
import { serviceColors } from "../../../core/constants/service-colors";
import { ServiceInterface } from "../../../store/service/interfaces";

export const BasicInformation = ({
  showErrors,
  service,
  onChange,
}: {
  showErrors: boolean,
  service: Omit<ServiceInterface, "id">,
  onChange: (service: Omit<ServiceInterface, "id">) => void,
}) => {
  return (
    <div className="flex flex-col w-full h-max bg-white/50 rounded-2xl pt-2">
      <div className="border-b border-b-1 px-5 py-3">
        <p className="text-slate-700 text-xl font-medium">
          Basic information
        </p>   

        <p className="text-slate-500 text-sm mt-2">
          Add the name of the service and give a brief description of what it does
        </p>
      </div>

      <div className="flex flex-col gap-5 w-full p-5">
        <Input
          value={service?.name}
          label="Name of the service"
          placeholder="Add the name of your service here"
          onChange={(name) => {
            onChange({ ...service, name })
          }}
          error={!showErrors ? undefined : !service.name ? "Please add a name for your service" : undefined}
        />

        <Input
          textarea
          value={service?.description}
          label="Description of your service"
          placeholder="Add the description of your service here"
          onChange={(description) => {
            onChange({ ...service, description })
          }}
          error={!showErrors ? undefined : !service.description ? "Please add a description for your service" : undefined}
        />

        <div>
          <p className="text-slate-700 font-medium mb-3 ml-1">
            Add a color to your service
          </p>

          <div className="flex gap-2 flex-wrap">
            {serviceColors.map((color) => {
              const isSelected = color === service.color

              return (
                <div
                  className={`${color} w-8 h-8 rounded-full flex items-center justify-center cursor-pointer hover:opacity-75 ${isSelected ? "border-2 border-gray-600" : ""}`}
                  onClick={() => {
                    onChange({ ...service, color })
                  }}
                >
                  <p className={`text-[10px]`}>
                    {isSelected ? service.name?.slice(0, 2).toUpperCase() : ""}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

