import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessages } from "../../store/agents/thunks";
import { selectCommerceId } from "../../store/commerce/selectors";
import { AppDispatch } from "../../store/store";

export const useLoadMessages = () => {
  const dispatch = useDispatch<AppDispatch>();
  const commerceId = useSelector(selectCommerceId);

  useEffect(() => {
    if (commerceId)
      dispatch(getMessages({ commerceId }));
  }, [commerceId, dispatch]);
}
