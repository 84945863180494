import { ExpandMoreOutlined } from "@mui/icons-material";
import { useState } from "react";
import { Input } from "../../../components/inputs/input";
import { currencies } from "../../../core/constants/prioritized-currencies";
import { useHandleClickOutside } from "../../../core/hooks";
import { ServiceInterface } from "../../../store/service/interfaces";

export const PricesAndDuration = ({
  showErrors,
  service,
  onChange,
}: {
  showErrors: boolean,
  service: Omit<ServiceInterface, "id">,
  onChange: (service: Omit<ServiceInterface, "id">) => void,
}) => {
  return (
    <div className="flex flex-col justify-center w-full h-max bg-white/50 rounded-2xl pt-2">
      <div className="border-b border-b-1 px-5 py-3">
        <p className="text-slate-700 text-xl font-medium">
          Prices and duration
        </p>   

        <p className="text-slate-500 text-sm mt-2">
          Configure the duration of your service and the price
        </p>
      </div>

      <div className="flex flex-col gap-5 w-full p-5">
        <div className="flex flex-col">
          <p className="text-slate-700 text-md font-medium ml-1 mb-2">
            Duration of your service:
          </p>

          <div className="flex gap-2 w-full">
            <Input
              type="number"
              label="Hours"
              value={Math.floor((service?.duration || 0) / 60)}
              onChange={(hour) => {
                onChange({ ...service, duration: (service.duration || 0) % 60 + parseInt(hour) * 60 })
              }}
              error={!showErrors ? undefined : !service.duration ? "Please add a duration for your service" : undefined}
            />

            <Input
              type="number"
              label="Minutes"
              value={Math.floor((service?.duration || 0) % 60)}
              onChange={(minute) => {
                onChange({ ...service, duration: Math.floor((service.duration || 0) / 60) * 60 + parseInt(minute) })
              }}
            />
          </div>
        </div>

        <PriceInput
          price={service.price}
          currency={service.currency}
          onChange={(price) => {
            onChange({ ...service, price: parseInt(price) })
          }}
          onChangeCurrency={(currency) => {
            onChange({ ...service, currency })
          }}
          showErrors={showErrors}
        />
      </div>
    </div>
  );
}

const PriceInput = ({
  price,
  currency,
  onChange,
  onChangeCurrency,
  showErrors,
}: {
  currency?: string,
  price?: number,
  onChange: (value: string) => void,
  onChangeCurrency: (value: string) => void,
  showErrors: boolean,
}) => {
  return (
    <div className="flex items-end gap-2">
      <CurrencySelector
        currency={currency}
        onChange={(currency) => {
          onChangeCurrency(currency);
        }}
      />

      <Input
        type="number"
        value={price}
        label="Price of the service"
        placeholder="Add the price of your service here"
        onChange={(price) => {
          onChange(price)
        }}
        error={!showErrors ? undefined : !price ? "Please add a price for your service" : undefined}
      />
    </div>
  );
}

const CurrencySelector = ({
  onChange,
  currency,
}: {
  currency?: string,
  onChange: (value: string) => void,
}) => {
  const [open, setOpen] = useState(false);

  const dropdownRef = useHandleClickOutside<HTMLDivElement>(() => {
    setOpen(false);
  });

  return (
    <div
      className=""
      ref={dropdownRef}
    >
      <div className="flex flex-col gap-2">
        <p className="text-slate-700 font-medium ml-1">
          Currency
        </p>

        <div
          className={`bg-white rounded-md h-[48px] flex gap-2 items-center p-3 cursor-pointer`}
          onClick={() => {
            setOpen(true);
          }}
        >
          <p className="text-sm text-slate-700">
            {currency}
          </p>

          <ExpandMoreOutlined
            style={{
              color: "rgb(51 65 85)",
            }}
          />
        </div>
      </div>

      {open &&
        <div
          className="relative"
        >
          <div
            className={`
              absolute bg-white w-40 right-0 -top-[310px] rounded-xl py-3 flex flex-col
              overflow-y-scroll h-[260px]
              scrollbar scrollbar-thumb-rounded-full scrollbar-thumb-indigo-400/25
              scrollbar-w-1.5
            `}
          >
            {currencies.map(({ name, emoji }) => {
              return (
                <p
                  className={`
                    hover:bg-gray-200/50 px-3 py-2 cursor-pointer flex items-center text-slate-700
                  `}
                  onClick={() => {
                    onChange(name)
                    setOpen(false);
                  }}
                >
                  {emoji} {name}
                </p>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
}
