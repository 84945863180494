import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlatformButton } from "../../../components/buttons/button";
import { Modal } from "../../../components/modal/modal";
import { selectAppointmentRepository } from "../../../store/appointment/selectors";
import { removeAppointmentThunk } from "../../../store/appointment/thunks";
import { selectCapabilityRepository } from "../../../store/capabilities/selectors";
import { removeCapabilityThunk } from "../../../store/capabilities/thunks";
import { selectCustomerLinkRepository } from "../../../store/customer-link/selectors";
import { selectCustomerRepository } from "../../../store/customer/selectors";
import { selectResourceRepository } from "../../../store/resource/selectors";
import { removeServiceThunk } from "../../../store/service/thunks";
import { AppDispatch } from "../../../store/store";

export const ConfirmServiceDeletionModal = ({
  serviceId,
  onClose,
  onDelete,
}: {
  serviceId: string,
  onClose: () => void,
  onDelete: () => void,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const resources = useSelector(selectResourceRepository);
  const capabilities = useSelector(selectCapabilityRepository);

  const serviceCapabilities = capabilities.findAll?.service_id(serviceId)
  const hasCapabilities = serviceCapabilities && serviceCapabilities.length > 0;
  const resourcesWithThisService = resources.findAll?.id(...(serviceCapabilities?.over?.resource_id || []))
  const [frozenResources] = useState(resourcesWithThisService);
  const [frozenCapabilities] = useState(serviceCapabilities);

  const customers = useSelector(selectCustomerRepository);
  const appointments = useSelector(selectAppointmentRepository);
  const customerLink = useSelector(selectCustomerLinkRepository);

  const serviceAppointments = appointments.findAll?.service_id(serviceId)
  const hasAppointments = serviceAppointments && serviceAppointments.length > 0;
  const customerLinksWithAppointments = customerLink.findAll?.customer_id(...(appointments.over?.customer_id || []))
  const customersWithAppointments = customers.findAll?.id(...(customerLinksWithAppointments?.over?.customer_id || []))
  const [frozenCustomers] = useState(customersWithAppointments);
  const [frozenAppointments] = useState(serviceAppointments);

  return (
    <Modal
      title="Confirm service deletion"
      onClose={onClose}
    >
      <div className="flex flex-col items-center p-5 text-center gap-10">
        <p className="text-slate-700 h-6">
          Are you sure you want to delete this service?<br/><br/>
        </p>

        {hasCapabilities &&
          <div className="flex flex-col gap-5">
            <p className="text-red-600">
              You have <span className="font-bold">{frozenCapabilities?.length}</span> resources that will be automatically <span className="font-bold">unassigned</span> this service:
            </p>

            <div className={`
              flex flex-wrap items-center gap-5 justify-center max-h-32 overflow-y-auto
              scrollbar scrollbar-thumb-rounded-full scrollbar-thumb-indigo-400/25
              scrollbar-w-1.5
            `}>
              {frozenResources?.fmap(({ name }) => {
                return (
                  <div className="flex gap-2 items-center">
                    <div className="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                      <p className="text-slate-700 font-medium text-sm">
                        {name?.slice(0, 2).toUpperCase()}
                      </p> 
                    </div>

                    <p>{name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        }

        {hasAppointments &&
          <div className="flex flex-col gap-5">
            <p className="text-red-600">
              You have <span className="font-bold">{frozenAppointments?.length}</span> appointments that will be automatically <span className="font-bold">cancelled</span> for these customers:
            </p>

            <div className={`
              flex flex-wrap items-center gap-5 justify-center max-h-32 overflow-y-auto
              scrollbar scrollbar-thumb-rounded-full scrollbar-thumb-indigo-400/25
              scrollbar-w-1.5
            `}>
              {frozenCustomers?.fmap(({ first_name, last_name, id }) => {
                const name = first_name && last_name ? `${first_name} ${last_name}` : id.slice(0, 8);

                return (
                  <div className="flex gap-2 items-center">
                    <div className="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                      <p className="text-slate-700 font-medium text-sm">
                        {name?.slice(0, 2).toUpperCase()}
                      </p> 
                    </div>

                    <p>{name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        }

        <div className={`flex max-lg:flex-col gap-5`}>
          <PlatformButton
            title="DELETE"
            onClick={async () => {
              await Promise.all([
                serviceCapabilities?.fmap(async ({ id }) => {
                  await dispatch(removeCapabilityThunk(id));
                })
              ])

              await Promise.all([
                serviceAppointments?.fmap(async ({ id }) => {
                  await dispatch(removeAppointmentThunk(id));
                })
              ])

              await dispatch(removeServiceThunk(serviceId));

              onClose();
              onDelete();
            }}
            className="bg-red-500 hover:bg-red-400 w-40 h-12"
            textClassName="text-white font-medium tracking-wide"
          />

          <PlatformButton
            title="CANCEL"
            onClick={async () => {
              onClose()
            }}
            className="bg-indigo-500 hover:bg-indigo-400 w-40 h-12"
            textClassName="text-white font-medium tracking-wide"
          />
        </div>
      </div>
    </Modal>
  );
}
