import { CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDeleteModal } from "../../../../../components/confirm-delete-modal/confirm-delete-modal";
import { WeekSchedule } from "../../../../../components/week-schedule/week-schedule";
import { selectCapabilities } from "../../../../../store/capabilities/selectors";
import { removeCapabilityThunk } from "../../../../../store/capabilities/thunks";
import { ResourceInterface } from "../../../../../store/resource/interfaces";
import { selectResources } from "../../../../../store/resource/selectors";
import {
  removeResourceThunk,
  updateResourceThunk,
} from "../../../../../store/resource/thunks";
import { ResourceTimeframeInterface } from "../../../../../store/resource_timeframe/interfaces";
import { selectResourceTimeframes } from "../../../../../store/resource_timeframe/selectors";
import {
  addResourceTimeframesThunk,
  removeResourceTimeframesThunk,
  removeResourceTimeframeThunk,
  updateResourceTimeframesThunk,
} from "../../../../../store/resource_timeframe/thunks";
import { selectServices } from "../../../../../store/service/selectors";
import { AppDispatch } from "../../../../../store/store";
import { AddResourceModal } from "./add-resource-modal";
import { ChooseServicesModal } from "./choose-services-modal";

export const TeamAndAvailability = () => {
  const resources = useSelector(selectResources);

  const [choosenResource, setChoosenResource] = useState<string>();

  const [openAddResourceModal, setOpenAddResourceModal] = useState(false);

  return (
    <div>
      {resources.map((resource, i) => {
        return (
          <ResourceItem
            key={i}
            resource={resource}
            index={i}
            isOpen={choosenResource === resource.id}
            onClick={() => {
              if (choosenResource === resource.id) {
                setChoosenResource(undefined);
              } else {
                setChoosenResource(resource.id);
              }
            }}
            onClose={() => {
              setChoosenResource(undefined);
            }}
          />
        );
      })}

      {openAddResourceModal && (
        <AddResourceModal
          onClose={() => {
            setOpenAddResourceModal(false);
          }}
        />
      )}

      <p
        className="text-sm text-indigo-500 p-3 cursor-pointer hover:text-indigo-400"
        onClick={() => {
          setOpenAddResourceModal(true);
        }}
      >
        + Add Resource
      </p>
    </div>
  );
};

const ResourceItem = ({
  resource,
  index,
  isOpen,
  onClick,
  onClose,
}: {
  resource: ResourceInterface;
  index: number;
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void,
}) => {
  const [openChooseServiceModal, setOpenChooseServiceModal] = useState(false);

  const capabilities = useSelector(selectCapabilities);
  const services = useSelector(selectServices).filter(({ id }) => {
    return capabilities.some((c) => {
      return c.service_id === id && c.resource_id === resource.id;
    });
  });

  const resourceTimeframes = useSelector(selectResourceTimeframes);
  const timeframes = resourceTimeframes.filter(({ resource_id }) => {
    return resource_id === resource.id;
  });

  const border = "border-b border-gray-300";

  return (
    <div
      className={`hover:bg-indigo-50 px-3 flex gap-6 items-start cursor-pointer`}
    >
      <div className={`${border} w-full`}>
        <div className={`py-4 flex justify-between gap-6`} onClick={onClick}>
          <p className="text-lg font-medium w-40">
            {resource.name ?? `Resource #${index + 1}`}
          </p>

          <ResourceTimeReminder timeframes={timeframes} />

          <div className="flex flex-col gap-2">
            <p
              className="text-xs text-indigo-500 cursor-pointer hover:text-indigo-400"
              onClick={(e) => {
                e.stopPropagation();
                setOpenChooseServiceModal(true);
              }}
            >
              Services +
            </p>

            {openChooseServiceModal && (
              <ChooseServicesModal
                resourceId={resource.id}
                onClose={() => {
                  setOpenChooseServiceModal(false);
                }}
              />
            )}

            <div className="flex flex-wrap gap-1">
              {services.map(({ name, color }) => {
                return (
                  <p
                    className={`${color} text-xs py-1 rounded-full w-12 text-gray-800 text-center`}
                  >
                    {name?.slice(0, 2).toUpperCase()}
                  </p>
                );
              })}
            </div>
          </div>
        </div>

        {isOpen &&
          <ResourceDropdown
            storeResource={resource}
            onClose={onClose}
          />
        }
      </div>
    </div>
  );
};

const ResourceTimeReminder = ({
  timeframes,
}: {
  timeframes: ResourceTimeframeInterface[];
}) => {
  // useMemo(() => {
  //   const ranges: { sd: string, ed: string }[] = [];
  //
  //   const days = Object.values(Day);
  //
  //   const daysInFrames = [...new Set(timeframes.map(({ day }) => day))];
  //
  //   const foo = [];
  //   for (let i = 0; i < days.length - 1; i++) {
  //     if (daysInFrames.includes(days[i])) {
  //       if (daysInFrames.includes(days[i + 1])) {
  //       }
  //     }
  //   }
  //
  //   Object.values(Day)
  // }, []);

  return (
    <div className="flex flex-col gap-2 items-start">
    </div>
  );
};

const ResourceDropdown = ({
  storeResource,
  onClose,
}: {
  storeResource: ResourceInterface;
  onClose: () => void,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [resource, setResource] = useState<ResourceInterface>(storeResource);

  const [loading, setLoading] = useState(false);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const resourceTimeframes = useSelector(selectResourceTimeframes);
  const timeframes = resourceTimeframes.filter(({ resource_id }) => {
    return resource_id === storeResource.id;
  });
  const capabilities = useSelector(selectCapabilities).filter(
    ({ resource_id }) => {
      return resource_id === resource.id;
    },
  );

  return (
    <div>
      <div className="flex flex-col gap-3 py-4">
        <div className="flex flex-col gap-1">
          <TextField
            value={resource?.name}
            label="Name"
            size="small"
            onChange={(e) => {
              setResource({
                ...resource,
                name: e.target.value,
              });
            }}
          />

          <TextField
            multiline
            value={resource?.description}
            label="Description"
            size="small"
            onChange={(e) => {
              setResource({
                ...resource,
                description: e.target.value,
              });
            }}
          />
        </div>

        <div className="w-full">
          <WeekSchedule
            timeframes={timeframes}
            onAddTimeframes={(t) => {
              dispatch(
                addResourceTimeframesThunk({
                  ...t,
                  resource_id: storeResource.id,
                }),
              );
            }}
            onRemoveTimeframes={(ids) => {
              dispatch(removeResourceTimeframesThunk(ids));
            }}
            onUpdateTimeframes={(t) => {
              dispatch(updateResourceTimeframesThunk(t));
            }}
          />
        </div>
      </div>

      <div className="flex items-center justify-end pb-3 gap-3">
        <p
          className={`
            text-white cursor-pointer mt-2 w-16 py-1 rounded-full
            border-2 font-medium flex items-center justify-center
            ${loading ?
              "bg-gray-300 border-gray-300 py-2"
              : "bg-indigo-500 border-indigo-500 hover:bg-indigo-400 hover:border-indigo-400 py-1"}
            `}
          onClick={async () => {
            setLoading(true);

            await dispatch(
              updateResourceThunk({
                ...resource,
              }),
            );
            onClose();

            setLoading(false);
          }}
        >
          {loading ?
            <CircularProgress
              size={18}
              sx={{
                color: "rgb(192 132 252)",
              }}
            />
          : "Save"}
        </p>

        {openConfirmDelete && 
          <ConfirmDeleteModal
            title="Confirm resource delete"
            onDelete={async () => {
              await Promise.all(
                [
                  Promise.all(
                    timeframes.map(async ({ id }) => {
                      return await dispatch(removeResourceTimeframeThunk(id));
                    })
                  ),
                  Promise.all(
                    capabilities.map(async ({ id }) => {
                      return await dispatch(removeCapabilityThunk(id));
                    })
                  ),
                ]
              )

              dispatch(removeResourceThunk(resource.id));
            }}
            onClose={() => {
              setOpenConfirmDelete(false);
            }}
          />
        }

        <p
          className="text-indigo-500 cursor-pointer mt-2 px-4 py-1 rounded-full border-2 border-indigo-500 hover:border-indigo-400 hover:bg-gray-100 hover:text-indigo-400 font-medium"
          onClick={() => {
            setOpenConfirmDelete(true);
          }}
        >
          Delete
        </p>
      </div>
    </div>
  );
};
