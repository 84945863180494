import React from "react";
import { PageMenu } from "./menu/menu-wrapper";
import { PageTopbar } from "./page-top-bar";

export const PageWrapper = ({
  children,
  page,
}: {
  children: React.ReactNode,
  page: string,
}) => {
  return (
    <>
      <Mobile page={page}>{children}</Mobile>

      <Desktop page={page}>{children}</Desktop>
    </>
  );
}

const Mobile = ({
  children,
  page,
}: {
  children: React.ReactNode,
  page: string,
}) => {
  return (
    <div className="lg:hidden bg-gradient-to-b from-gray-200 to-indigo-200 w-full h-screen p-5">
      <PageTopbar
        page={page}
      />

      {children}
    </div>
  );
}

const Desktop = ({
  children,
  page,
}: {
  children: React.ReactNode,
  page: string,
}) => {
  return (
    <div className="max-lg:hidden bg-gradient-to-b from-gray-200 to-indigo-200 w-full h-screen">
      <div className="h-full w-full flex">
        <PageMenu page={page} />

        <div className="p-10 flex flex-col h-full w-full">
          <div className="h-full">
            <PageTopbar
              page={page}
            />

            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
