export class List<T> {
  constructor(
    public data: T[]
  ) {}

  fmap<K>(f: (v: T) => K): K[] {
    return this.data.map(f);
  }

  filter(f: (v: T) => boolean): List<T> {
    return new List<T>(this.data.filter(f));
  }

  fold<K>(f: (acc: K, current: T) => K, init: K): K {
    return this.data.reduce(f, init);
  }

  exclude(value: T): List<T> {
    return this.filter((v) => v !== value)
  }

  includes(value: T): boolean {
    return this.data.includes(value)
  }

  concat(...values: T[]): List<T> {
    return new List(this.data.concat(...values))
  }

  append(value: T): List<T> {
    return new List([...this.data, value])
  }
}
