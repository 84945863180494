import { Checkbox } from "../../../components/checkbox/checkbox";
import { List } from "../../../core/repository/list";
import { Repository } from "../../../core/repository/repository";
import { ResourceInterface } from "../../../store/resource/interfaces";

export const ChooseResource = ({
  resources,
  capabilities,
  onChange,
}: {
  resources: Repository<ResourceInterface>,
  capabilities: List<string>,
  onChange: (id: string) => void,
}) => {
  return (
    <div className="flex flex-col justify-center w-full bg-white/50 rounded-2xl pt-2">
      <div className="border-b border-b-1 px-5 py-3">
        <p className="text-slate-700 text-xl font-medium">
          Assign to resources
        </p>   

        <p className="text-slate-500 text-sm mt-2">
          Assign this service to your resources
        </p>
      </div>

      <div className="flex flex-wrap gap-5 p-5">
        {resources.fmap(({ name, id }) => {
          if (!name) return

          return (
            <div className="flex gap-2 items-center">
              <Checkbox
                value={capabilities.includes(id)}
                onClick={() => {
                  onChange(id);
                }}
              />
              
              <div className="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                <p className="text-slate-700 font-medium text-sm">
                  {name?.slice(0, 2).toUpperCase()}
                </p> 
              </div>

              <p>{name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
